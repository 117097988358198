<template>
	<div id="mess-form">
		<ValidationObserver ref="form" v-slot="{ handleSubmit }">
			<form @submit.prevent="handleSubmit(applyForm)">
				<div class="mess-form__header">
					<h5 v-if="!isUpdate" class="mb-3">{{ $t("MessageTitle.CreateMessage") }}</h5>

					<h5 v-else class="mb-3">{{
						$t("MessageTitle.MessageID", {
							id: id,
						})
					}}</h5>
				</div>

				<div class="mess-form__container">
					<!-- Customer ID -->
					<!-- Update View -->
					<div v-if="isUpdate" class="form-group">
						<label for="id" class="form-group__label">{{ $t("MessageForm.ID") }}</label>
						<div class="form-group__input">{{ id }}</div>
					</div>

					<!-- Customer Object -->
					<div class="form-group required">
						<label for="object" class="form-group__label col-form-label">{{
							$t("MessageForm.CustomerObject")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								ref="admin-object"
								v-slot="{ classes, errors }"
								name="admin_object"
								rules="required"
							>
								<div :class="[classes]">
									<multiselect
										id="object"
										v-model="messageForm.customerObject"
										:limit-text="limitTextObject"
										:custom-label="labelObject"
										:options="objects"
										:close-on-select="true"
										:show-labels="false"
										:allow-empty="true"
										:searchable="false"
										:multiple="true"
										:limit="limitVisibleDD"
										placeholder=""
										@close="checkValidate('admin-object')"
									>
										<template slot="clear">
											<div
												v-if="get(messageForm, 'customerObject.length')"
												class="multiselect__clear"
												@mousedown.prevent.stop="clearAll('customerObject')"
											></div>
										</template>
										<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
										<template #noResult>{{ $t("DropDown.NoResult") }}</template>
									</multiselect>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Short Message -->
					<div class="form-group required">
						<label for="short-message" class="form-group__label col-form-label">{{
							$t("MessageForm.ShortMessage")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="admin_short_message"
								rules="required"
							>
								<div :class="[classes]">
									<textarea
										id="short-message"
										v-model.trim="messageForm.shortMessage"
										class="form-control"
										rows="2"
										:maxlength="shortMessLength"
									></textarea>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Message Content -->
					<div class="form-group required">
						<label for="message" class="form-group__label col-form-label">{{
							$t("MessageForm.MessageContent")
						}}</label>
						<div class="form-group__input">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="admin_message"
								rules="required"
							>
								<div :class="[classes]">
									<textarea
										id="message"
										v-model.trim="messageForm.body"
										class="form-control"
										rows="3"
										:maxlength="messageLength"
									></textarea>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Send times -->
					<div v-for="month in months" :key="month" class="form-group form-group-time" stretch>
						<label for="time" class="form-group__label col-form-label">{{
							$t("MessageForm.Month", { month: month })
						}}</label>
						<div class="form-group__input">
							<div class="group-time">
								<div v-for="week in weeks" :key="week" class="group-time__item">
									<span class="group-time__text">{{
										$t("MessageForm.Week", { week: 4 * (month - 1) + week })
									}}</span>
									<multiselect
										v-if="messageForm.setting && messageForm.setting.length"
										id="week"
										v-model="messageForm.setting[4 * (month - 1) + week - 1].times"
										:options="times"
										:close-on-select="true"
										:show-labels="false"
										:allow-empty="false"
										:searchable="false"
										placeholder=""
										class="group-time__input"
										@open="openSendTime(4 * (month - 1) + week - 1)"
										@remove="clearOption(4 * (month - 1) + week - 1)"
									>
										<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
										<template #noResult>{{ $t("DropDown.NoResult") }}</template>
										<template slot="clear">
											<div
												v-if="messageForm.setting[4 * (month - 1) + week - 1].times"
												class="multiselect__clear"
												@mousedown.prevent.stop="clearOption(4 * (month - 1) + week - 1)"
											>
												<font-awesome-icon :icon="['fas', 'times']" :title="$t('Action.Clear')" />
											</div>
										</template>
									</multiselect>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="mess-form__action text-center">
					<CButton v-bind="{ variant: 'outline' }" color="info" type="submit">{{
						textSubmit
					}}</CButton>
					<CButton
						v-if="isUpdate"
						v-bind="{ variant: 'outline' }"
						color="warning"
						@click="toggleDeleteModal(true)"
						>{{ $t("Button.Delete") }}</CButton
					>
					<CButton v-bind="{ variant: 'outline' }" color="danger" @click="cancelForm">{{
						$t("Button.Exit")
					}}</CButton>
				</div>
			</form>
		</ValidationObserver>

		<!-- Show modal when delete form -->
		<CustomModal
			v-if="isShowDeleteModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="deleteMessage"
			@cancel-modal="toggleDeleteModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.AreYouSure") }}</p>
				<span>{{ $t("MessageForm.DeleteDescription") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when cancel if messageForm was changed -->
		<CustomModal
			v-if="isShowDiscardModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="discardChanges"
			@cancel-modal="toggleDiscardModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.DiscardChanges") }}</p>
				<span>{{ $t("Button.DiscardContent") }}</span>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import {
	CUSTOMER_OBJECT,
	MESS_MONTH,
	MESS_WEEK,
	MESS_TIMES,
	INPUT_LENGTH,
} from "@/shared/plugins/constants"
import { CHANGE_STATUS_DISCARD_MODAL, CHANGE_STATUS_DELETE_MODAL } from "./../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapMutations, mapState } = createNamespacedHelpers("messages")
import { get, isEqual, cloneDeep } from "lodash-es"
import Swal from "sweetalert2"
import store from "@/store"

export default {
	name: "MessageForm",

	components: {},

	props: {
		id: {
			type: [Number, String],
			default: null,
		},

		isUpdate: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			originForm: {
				customerObject: [],
				body: "",
				shortMessage: "",
				setting: cloneDeep(store.state.messages.setting),
			},

			messageForm: {},

			limitVisibleDD: INPUT_LENGTH.VISIBLE_DROPDOWN,
			messageLength: INPUT_LENGTH.MESSAGE,
			shortMessLength: INPUT_LENGTH.SHORT_MESSAGE,

			objects: [
				CUSTOMER_OBJECT.ALL,
				CUSTOMER_OBJECT.MSM,
				CUSTOMER_OBJECT.TRANS_WOMAN,
				CUSTOMER_OBJECT.TRANS_MAN,
				CUSTOMER_OBJECT.TCMT,
				CUSTOMER_OBJECT.PROSTITUTE,
				CUSTOMER_OBJECT.HETEROSEXUAL_PARTNER,
				CUSTOMER_OBJECT.OTHER,
			],

			months: [...MESS_MONTH],
			weeks: [...MESS_WEEK],
			times: [...MESS_TIMES],
		}
	},

	computed: {
		...mapState(["recentMess", "isShowDiscardModal", "isShowDeleteModal"]),

		textSubmit: function () {
			return this.isUpdate ? this.$t("Button.SaveChanges") : this.$t("Button.CreateNew")
		},
	},

	watch: {
		recentMess: function () {
			this.setUpData()
		},

		"messageForm.customerObject": function (val) {
			if (val[val?.length - 1] === CUSTOMER_OBJECT.ALL) {
				this.messageForm.customerObject = [...this.objects]
				const index = this.messageForm.customerObject.indexOf(CUSTOMER_OBJECT.ALL)
				this.messageForm.customerObject.splice(index, 1)
			}
		},
	},

	created() {
		this.setUpData()
	},

	beforeDestroy() {},

	methods: {
		get,

		...mapMutations({
			CHANGE_STATUS_DISCARD_MODAL,
			CHANGE_STATUS_DELETE_MODAL,
		}),

		setUpData() {
			const dataForm = this.isUpdate ? { ...this.recentMess } : { ...this.originForm }
			this.messageForm = cloneDeep(dataForm)
		},

		applyForm() {
			if (!this.messageForm.setting.some(item => item.times > 0)) {
				Swal.fire({
					text: this.$t("MessageForm.RequiredSendTime"),
					confirmButtonColor: "#3A99D7",
					confirmButtonText: this.$t("Button.OK"),
					backdrop: false,
				})

				return
			}

			const setting = this.messageForm.setting.filter(item => item.times >= 1)
			this.$emit("apply-form", {
				...this.messageForm,
				setting: setting,
			})
		},

		cancelForm() {
			let isEq
			if (this.isUpdate) isEq = isEqual(this.messageForm, this.recentMess)
			else isEq = isEqual(this.messageForm, this.originForm)

			if (!isEq) this.toggleDiscardModal(true)
			else this.$emit("cancel-form")
		},

		discardChanges() {
			this.toggleDiscardModal(false)
			this.$emit("cancel-form")
		},

		toggleDiscardModal(status = false) {
			this.CHANGE_STATUS_DISCARD_MODAL(status)
		},

		deleteMessage() {
			this.$emit("delete-form", this.messageForm)
			this.toggleDeleteModal(false)
		},

		toggleDeleteModal(status = false) {
			this.CHANGE_STATUS_DELETE_MODAL(status)
		},

		labelObject(value) {
			return {
				[CUSTOMER_OBJECT.ALL]: this.$t("MessageForm.All"),
				[CUSTOMER_OBJECT.MSM]: this.$t("MessageForm.MSM"),
				[CUSTOMER_OBJECT.TRANS_WOMAN]: this.$t("MessageForm.TransWoman"),
				[CUSTOMER_OBJECT.TRANS_MAN]: this.$t("MessageForm.TransMan"),
				[CUSTOMER_OBJECT.TCMT]: this.$t("MessageForm.TCMT"),
				[CUSTOMER_OBJECT.PROSTITUTE]: this.$t("MessageForm.Prostitute"),
				[CUSTOMER_OBJECT.HETEROSEXUAL_PARTNER]: this.$t("MessageForm.HeterosexualPartner"),
				[CUSTOMER_OBJECT.OTHER]: this.$t("MessageForm.Other"),
			}[value]
		},

		clearAll(array, field) {
			this.messageForm[array] = null
			this.$refs[field].validate()
		},

		limitTextObject(count) {
			return this.$t("MessageForm.OtherObjects", { count: count })
		},

		checkValidate(field) {
			if (this.$refs[field]) this.$refs[field].validate()
		},

		openSendTime(index) {
			if (!this.messageForm.setting[index].times) this.messageForm.setting[index].times = 1
		},

		clearOption(index) {
			this.messageForm.setting[index].times = null
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.mess-form {
	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__container {
		display: grid;
		grid-gap: 10px 20px;
		width: 100%;
		margin-bottom: 15px;
		background-color: $color-white;
		border-radius: 12px;
	}

	&__action {
		display: flex;
		justify-content: space-between;

		@media (min-width: $xxs) {
			justify-content: center;

			.btn + .btn {
				margin-left: 40px;
			}
		}
	}
}

.form-group {
	display: flex;
	flex-wrap: wrap;
	gap: 2%;
	margin-bottom: 0;

	&[stretch] {
		.form-group__input {
			flex: 0 0 100%;
			max-width: 100%;

			@media (min-width: $xs) {
				flex: 0 0 75%;
				max-width: 75%;
			}
		}
	}

	&__label {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 18%;
			max-width: 18%;
		}
	}

	&__input {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 75%;
			max-width: 75%;
		}

		@media (min-width: $s) {
			flex: 0 0 50%;
			max-width: 50%;
		}

		@media (min-width: $ms) {
			flex: 0 0 30%;
			max-width: 30%;
		}
	}
}

.form-group.required > label::after {
	content: "*";
	color: $color-red;
	margin-left: 2px;
}

.group-time {
	display: flex;
	flex-wrap: wrap;
	gap: 5%;

	&__text {
		align-self: center;
	}

	&__input {
		width: 75px;
	}

	&__add {
		text-decoration: underline;
		align-self: center;
	}

	&__item {
		display: grid;
		gap: 10px;
		width: 120px;
		grid-template-columns: auto 78px;
		margin-bottom: 10px;
	}
}

.group-time + .error-mess {
	position: relative;
	top: -8px;
}

/deep/ .multiselect {
	font-size: 14px;
	&__single {
		font-size: 14px;
	}

	&__tag {
		margin-bottom: 0;
	}
}

.form-group-time {
	margin-bottom: 10px;
}
</style>
